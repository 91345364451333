<template>
	<CCol md="9">
		<CRow>
			<CCol md="4">
				<BaseDropDown
					:value="categoryParentLevelOne"
					:options="categoryParentLevelOneOptions"
					:searchable="false"
					:allow-empty="false"
					label="name"
					track-by="value"
					class="select-custom"
					label-drop-down="Category level1"
					data-test-id="category-lv-1"
					@input="handleSelectedCategory"
				/>
			</CCol>
			<CCol md="4">
				<BaseDropDown
					:value="categoryParentLevelTwo"
					:options="categoryParentLevelTwoOptions"
					:searchable="false"
					:allow-empty="false"
					:disabled="categoryParentLevelTwoOptions.length <= 1"
					:placeholder="allOptionSubcategoriesLabel"
					label="name"
					track-by="value"
					class="select-custom"
					label-drop-down="Category level2"
					data-test-id="category-lv-2"
					@input="handleSelectedCategory"
				/>
			</CCol>
			<CCol md="4">
				<BaseDropDown
					:value="categoryParentLevelThree"
					:options="categoryParentLevelThreeOptions"
					:searchable="false"
					:allow-empty="false"
					:disabled="categoryParentLevelThreeOptions.length <= 1"
					:placeholder="allOptionSubcategoriesLabel"
					label="name"
					track-by="value"
					class="select-custom"
					label-drop-down="Category level3"
					data-test-id="category-lv-3"
					@input="handleSelectedCategory"
				/>
			</CCol>
		</CRow>
	</CCol>
</template>

<script>
import { CATEGORY_LEVEL } from '../enums/categories';
import { transformedCategoryDropdownOption } from '../assets/js/transform/category';
import { getCategoryChildren } from '../assets/js/helpers';

export default {
	name: 'ProductFilterCategory',
	props: {
		categoryLists: {
			type: Array,
			default: () => [],
		},
		categoryInfo: {
			type: Object,
			default: () => ({}),
		},
		categoryId: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			allOptionSubcategoriesLabel: 'All sub-categories',
		};
	},
	computed: {
		categoryParentLevelOneOptions() {
			const allOption = { name: 'All categories', value: null };
			const unassignOption = { name: 'Unassigned', value: -1 };
			const list = this.categoryLists.map(transformedCategoryDropdownOption);

			return [allOption, unassignOption, ...list];
		},
		categoryParentLevelTwoOptions() {
			if (this.categoryParentLevelOne) {
				const { name, value: id } = this.categoryParentLevelOne;
				const list = getCategoryChildren(this.categoryLists, id);

				const allOptionLabel = id && id !== -1 ? `All ${name}` : this.allOptionSubcategoriesLabel;
				const allOption = { name: allOptionLabel, value: null };

				return [allOption, ...list].map(transformedCategoryDropdownOption);
			}
			return [];
		},
		categoryParentLevelThreeOptions() {
			if (this.categoryParentLevelOne && this.categoryParentLevelTwo) {
				const levelOneId = this.categoryParentLevelOne.value;
				const levelOnelist = getCategoryChildren(this.categoryLists, levelOneId);

				if (levelOnelist.length) {
					const { name, value: levelTwoId } = this.categoryParentLevelTwo;
					const levelTwolist = getCategoryChildren(levelOnelist, levelTwoId);

					const allOptionLabel = levelTwolist.length ? `All ${name}` : this.allOptionSubcategoriesLabel;
					const allOption = { name: allOptionLabel, value: null };

					return [allOption, ...levelTwolist].map(transformedCategoryDropdownOption);
				}
			}

			return [];
		},
		categoryParentLevelOne() {
			// Find parent lv 1
			const parentOneId = (this.categoryInfo.level === CATEGORY_LEVEL.ONE)
				? this.categoryId
				: this.categoryInfo.parentLevelOneId;

			return this.categoryParentLevelOneOptions
				.find((option) => option.value === parentOneId);
		},
		categoryParentLevelTwo() {
			// Find parent lv 2
			const parentTwoId = (this.categoryInfo.level === CATEGORY_LEVEL.TWO)
				? this.categoryId
				: this.categoryInfo.parentLevelTwoId;

			return this.categoryParentLevelTwoOptions
				.find((option) => option.value === parentTwoId);
		},
		categoryParentLevelThree() {
			const parentThreeId = (this.categoryInfo.level === CATEGORY_LEVEL.THREE)
				? this.categoryId
				: null;

			return this.categoryParentLevelThreeOptions
				.find((option) => option.value === parentThreeId);
		},
	},
	methods: {
		handleSelectedCategory({ value }) {
			let id;

			switch (this.categoryInfo.level) {
				case CATEGORY_LEVEL.TWO:
					id = value || this.categoryInfo.parentLevelOneId;
					break;
				case CATEGORY_LEVEL.THREE:
					id = value || this.categoryInfo.parentLevelTwoId;
					break;
				default:
					id = value;
					break;
			}

			this.$emit('onCategoryChange', id);
		},
	},
};
</script>
