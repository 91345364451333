<template>
	<CCol md="3">
		<BaseDropDown
			v-model="status"
			:options="PRODUCT_STATUS_OPTIONS"
			:searchable="false"
			:allow-empty="false"
			class="select-custom"
			label="name"
			track-by="value"
			label-drop-down="Product status"
			@input="handleSelectStatus"
		/>
	</CCol>
</template>

<script>
import { PRODUCT_STATUS_OPTIONS } from '../enums/products';
import { getProductStatusValue } from '../assets/js/helpers';

export default {
	name: 'ProductFilterStatus',
	props: {
		isActive: {
			type: Number,
			default: null,
		},
		isEol: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			PRODUCT_STATUS_OPTIONS,
		};
	},
	computed: {
		status: {
			get() {
				const statusValue = getProductStatusValue(this.isEol, this.isActive);

				return PRODUCT_STATUS_OPTIONS.find((status) => status.value === statusValue);
			},
			set(value) {
				return value;
			},
		},
	},
	methods: {
		handleSelectStatus({ value }) {
			const mappingStatuses = {
				active: { isActive: 1, isEOL: null },
				inactive: { isActive: 0, isEOL: null },
				eol: { isActive: null, isEOL: 1 },
			};

			const statusValue = mappingStatuses[value] || { isActive: null, isEOL: null };

			this.$emit('onStatusChange', statusValue);
		},
	},
};
</script>
