<template>
	<div>
		<div class="sticky-container">
			<CRow>
				<CCol class="text-right">
					<CDropdown
						:disabled="isExporting"
						placement="bottom-end"
						data-test-id="export"
						add-menu-classes="export-dropdown-menu"
						class="btn-export mb-3 mr-3 pr-0"
						color="tertiary"
					>
						<CDropdownItem @click="exportProduct(PRODUCT_EXPORT.PRODUCT_INFO)">
							Product info
						</CDropdownItem>
						<CDropdownItem @click="exportProduct(PRODUCT_EXPORT.PRODUCT_INFO_DYNAMIC_FIELD)">
							Product info V.2 (Dynamic Sheet)
						</CDropdownItem>
						<CDropdownItem @click="exportProduct(PRODUCT_EXPORT.PRODUCT_PRICE)">
							Price
						</CDropdownItem>
						<CDropdownItem @click="exportProduct(PRODUCT_EXPORT.PRODUCT_GROUP)">
							Product group
						</CDropdownItem>
						<CDropdownItem @click="exportProduct(PRODUCT_EXPORT.PRODUCT_SHORT_ATTRIBUTE)">
							Product short attribute
						</CDropdownItem>
						<CDropdownItem @click="exportProduct(PRODUCT_EXPORT.APPLE_CARE_PRODUCTS)">
							Apple Care
						</CDropdownItem>
						<template #toggler-content>
							{{ isExporting ? "Exporting" : "Export" }}
						</template>
					</CDropdown>
					<CDropdown
						placement="bottom-end"
						add-menu-classes="import-dropdown-menu"
						class="btn-import mb-3 pr-0"
						data-test-id="import"
						color="tertiary"
						toggler-text="Import"
					>
						<CDropdownItem data-test-id="import-product-info" @click="importProductInfo">
							Product info
						</CDropdownItem>
						<CDropdownItem data-test-id="import-product-info" @click="importProductInfoDynamicField">
							Product info V.2 (Dynamic Sheet)
						</CDropdownItem>
						<CDropdownItem @click="importProductPrice">
							Price
						</CDropdownItem>
						<CDropdownItem @click="importProductGroup">
							Product group
						</CDropdownItem>
						<CDropdownItem @click="importProductShortAttribute">
							Product short attribute
						</CDropdownItem>
						<CDropdownItem @click="importProductAppleCare">
							Apple Care
						</CDropdownItem>
						<CDropdownDivider />
						<CDropdownItem href="/products/import/history">
							Import History
						</CDropdownItem>
					</CDropdown>
				</CCol>
			</CRow>
			<CRow>
				<CCol class="d-flex align-items-start">
					<form
						class="search-form flex-fill"
						@submit.prevent="handleSearch"
					>
						<CInput
							v-model.trim="queryParams.q"
							data-test-id="search-input"
							placeholder="Search by SKU number and product name"
						>
							<template #prepend-content>
								<CIcon name="cil-magnifying-glass" data-test-id="search-button" />
							</template>
						</CInput>
					</form>
					<BaseFilterButton
						:is-open="isShowFilter"
						:has-filter="hasFilter"
						class="ml-3"
						data-test-id="filter-button"
						@onClick="toggleFilter"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<transition name="fade">
						<div
							v-if="isShowFilter"
							class="filter-box rounded-sm pt-3 px-3 mb-3"
							data-test-id="show-filter-box"
						>
							<CRow>
								<ProductsFilterCategory
									:category-info="categoryInfo"
									:category-lists="categoryLists"
									:category-id="filterCategoryId"
									@onCategoryChange="handleCategory"
								/>
								<CCol md="3">
									<BaseDropDown
										:value="getSelectedValue(queryParams.brand_id, brandList)"
										:options="brandList"
										:searchable="true"
										:allow-empty="true"
										class="select-custom"
										label="name"
										track-by="value"
										label-drop-down="Brand"
										@input="handleFilterChange('brand_id', $event)"
									/>
								</CCol>
								<ProductsFilterStatus
									:is-eol="queryParams.is_eol"
									:is-active="queryParams.is_active"
									@onStatusChange="handleStatus"
								/>
								<CCol md="3">
									<CInput
										:value="priceRange"
										label="Selling price range"
										add-input-classes="text-right price-input bg-white"
										prepend="฿"
										placeholder="All prices"
										readonly
										type="text"
										@click="$refs['modal-price-range'].open()"
									/>
								</CCol>
								<CCol md="3">
									<BaseInputDate
										:value="filterCreatePeriod"
										label="Created period"
										placeholder="All periods"
										mode="range"
										@input="handleFilterCreateChange"
									>
										<template #append-content>
											<CIcon class="cil-calendar" name="cil-calendar" />
										</template>
									</BaseInputDate>
								</CCol>
								<CCol md="3">
									<BaseDropDown
										:value="getSelectedValue(queryParams.type, PRODUCT_TYPE_OPTIONS)"
										:options="PRODUCT_TYPE_OPTIONS"
										:searchable="false"
										:allow-empty="false"
										class="select-custom"
										label="name"
										track-by="value"
										label-drop-down="Product type"
										@input="handleFilterChange('type', $event)"
									/>
								</CCol>
								<CCol md="3">
									<BaseDropDown
										:value="getSelectedValue(queryParams.is_new, NEW_PRODUCT_STATUS_OPTIONS)"
										:options="NEW_PRODUCT_STATUS_OPTIONS"
										:searchable="false"
										:allow-empty="false"
										class="select-custom"
										label="name"
										track-by="value"
										label-drop-down="New product"
										@input="handleFilterChange('is_new', $event)"
									/>
								</CCol>
								<CCol md="3">
									<BaseDropDown
										:value="filterVisibility"
										:options="PRODUCT_VISIBILITY_STATUS_OPTIONS"
										:searchable="false"
										:allow-empty="false"
										class="select-custom"
										label="name"
										track-by="value"
										label-drop-down="Visibility"
										@input="handleFilterVisibilityChange"
									/>
								</CCol>
							</CRow>
							<CRow>
								<CCol md="3">
									<CInputCheckbox
										class="item-filter-mergin-bottom"
										label="Show only product group"
										value="option"
										:checked="!!$route.query.show_only_product_group"
										custom
										@update:checked="handleShowOnlyProductGroup"
									/>
								</CCol>
							</CRow>
						</div>
					</transition>
				</CCol>
			</CRow>
			<CRow class="mb-4">
				<CCol>
					<BaseSearchFormFooter
						:count="list.meta.total"
						append-text="product(s) found"
						@onReset="resetFilter"
					/>
				</CCol>
			</CRow>
		</div>

		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:pagination="{
						pages: pagination.lastPage,
						activePage: pagination.currentPage
					}"
					:fields="PRODUCT_TABLE_FIELDS"
					:items="products"
					:sort-key="queryParams.sort_by"
					:sort-direction="queryParams.sort_direction"
					striped
					link-to="ProductEdit"
					vertical-align="top"
					class="table-custom table-product table-custom-link "
					@onPaginationClick="handlePageChange"
					@onSortClick="handleSortChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t("global.searchNotFound", { field: "products" }) }}
							</p>
						</div>
					</template>
					<template #thumbnail="{item}">
						<img
							:src="item"
							class="thumbnail"
							alt="image"
						>
					</template>
					<template #visibility="{item}">
						<ul
							v-if="item.isListVisible || item.isDetailVisible || item.isSearchVisible"
							class="visibility-list"
						>
							<template v-for="(visibilityItem, key) in Object.keys(item)">
								<li v-if="item[visibilityItem]" :key="key" class="mb-2">
									{{ VISIBILITY_ITEM_MAPPER[visibilityItem] }}
								</li>
							</template>
						</ul>
						<template v-else>
							Hidden all
						</template>
					</template>
					<template #categories="{item}">
						<ul v-if="item.length" class="category-list">
							<li v-for="(category, key) in item" :key="key" class="mb-2">
								{{ category }}
							</li>
						</ul>
						<template v-else>
							n/a
						</template>
					</template>
					<template #status="{item}">
						<CBadge class="badge-status" :color="item.value">
							{{ item.name }}
						</CBadge>
					</template>
					<template #stockTotal="{item}">
						{{ item.cache | numberFormat(true) }}

						<div class="typo-caption-2 realtime-stock mt-1">
							<span class="realtime-stock-title">Real time: </span> <span class="realtime-stock-value">{{ item.realTime | numberFormat(true) }}</span>
						</div>
					</template>
					<template #stockReservedAndCommitted="{item}">
						{{ item.cache | numberFormat(true) }}

						<div class="typo-caption-2 realtime-stock mt-1">
							<span class="realtime-stock-title">Real time: </span> <span class="realtime-stock-value">{{ item.realTime | numberFormat(true) }}</span>
						</div>
					</template>
					<template #stockAvailable="{item}">
						{{ item.cache | numberFormat(true) }}

						<div class="typo-caption-2 realtime-stock mt-1">
							<span class="realtime-stock-title">Real time: </span> <span class="realtime-stock-value">{{ item.realTime | numberFormat(true) }}</span>
						</div>
					</template>
				</BaseTable>
			</CCol>
		</CRow>

		<!-- IMPORTING MODAL -->
		<CModal
			centered
			:show.sync="modalImporting.isUploading"
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					title="Importing"
					@onClose="cancelUpload"
				/>
			</template>
			<template #default>
				<div class="py-5">
					<BaseLoading v-if="modalImporting.isUploading" is-full-page />
				</div>
			</template>
			<template #footer>
				<CButton
					class="transparent mx-auto"
					color="tertiary"
					@click="cancelUpload"
				>
					Cancel
				</CButton>
			</template>
		</CModal>

		<!-- IMPORT ERROR MODAL -->
		<CModal
			centered
			class="modal-dialog-scrollable"
			:show.sync="modalImportError.isShowing"
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					title="Unable to import"
					@onClose="closeImportErrorDialog"
				/>
			</template>
			<template #default>
				<div class="mb-3 mt-1">
					An unexpected error occurred. Please double check the following rows, and try again.
				</div>
				<div
					v-for="(error, index) in modalImportError.errors"
					:key="index"
					class="mb-3"
				>
					{{ error }}
				</div>
			</template>
			<template #footer>
				<CButton
					class="transparent"
					color="tertiary"
					@click="closeImportErrorDialog"
				>
					Okay
				</CButton>
			</template>
		</CModal>

		<!-- Over export limit 2000 modal-->
		<CModal
			:show="modalExportLimit.isShowing"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					title="Product export limit to 2000"
					@onClose="handleCloseModalExportLimit"
				/>
			</template>
			<template #default>
				<div class="mb-3 mt-1">
					Number of records is more than 2000, please try to reduce the selected records and export again
				</div>
			</template>
			<template #footer>
				<CButton
					class="transparent"
					color="primary"
					@click="handleCloseModalExportLimit"
				>
					OK
				</CButton>
			</template>
		</CModal>

		<ModalPriceRange
			ref="modal-price-range"
			:from="queryParams.selling_price_from"
			:to="queryParams.selling_price_to"
			@onConfirm="handleFilterPriceChange"
		/>
		<BaseModalConfirm
			ref="modal-export"
			:is-submitting="isExporting"
			:close-after-confirm="false"
			title="Export report"
			description="The report will be sent to your email after combinded all the files. It might be taking for 5 - 30 mins, please be patient."
			primary-button-text="Send"
			primary-button-loading-text="Sending"
			@onConfirm="handleExportProduct"
		/>
	</div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapState, mapGetters, mapActions } from 'vuex';
import { CancelToken } from 'axios';
import ProductsFilterCategory from '@/components/ProductsFilterCategory.vue';
import ProductsFilterStatus from '@/components/ProductsFilterStatus.vue';
import BaseInputDate from '@/components/BaseInputDate.vue';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import ModalPriceRange from '@/components/ModalPriceRange.vue';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import {
	PRODUCT_TABLE_FIELDS,
	PRODUCT_STATUS_OPTIONS,
	PRODUCT_TYPE_OPTIONS,
	NEW_PRODUCT_STATUS_OPTIONS,
	PRODUCT_VISIBILITY_STATUS_OPTIONS,
	PRODUCT_VISIBILITY_STATUSES,
	PRODUCT_EXPORT,
} from '../enums/products';
import { transformedBrandDropdownOption } from '../assets/js/transform/brand';
import { VISIBILITY_ITEM_MAPPER } from '../enums/productLabels';
import {
	cleanObject,
	randomSearchString,
	numberFormat,
	priceFromAPI,
	hasSearchFilter,
	getBooleanQueryParameter,
	convertDateRangeToUTC,
} from '../assets/js/helpers';
import {
	exportProductInfoAPI,
	exportProductInfoDynamicFieldAPI,
	exportProductPriceAPI,
	exportProductGroupAPI,
	exportProductShortAttributeAPI,
	exportProductAppleCareAPI,
} from '../services/api/export.api';
import {
	importProductInfoAPI,
	importProductInfoDynamicFieldAPI,
	importProductPriceAPI,
	importProductGroupAPI,
	importProductShortAttributeAPI,
	importProductAppleCareAPI,
} from '../services/api/import.api';

export default {
	name: 'Products',
	components: {
		ProductsFilterCategory,
		ProductsFilterStatus,
		BaseInputDate,
		BaseModalConfirm,
		ModalPriceRange,
		ModalHeaderWrapper,
	},
	data() {
		return {
			PRODUCT_TABLE_FIELDS,
			VISIBILITY_ITEM_MAPPER,
			PRODUCT_STATUS_OPTIONS,
			PRODUCT_TYPE_OPTIONS,
			NEW_PRODUCT_STATUS_OPTIONS,
			PRODUCT_VISIBILITY_STATUS_OPTIONS,
			PRODUCT_EXPORT,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				category_id: Number(this.$route.query.category_id) || null,
				is_active: getBooleanQueryParameter(this.$route.query.is_active),
				is_eol: getBooleanQueryParameter(this.$route.query.is_eol),
				is_assign_category: getBooleanQueryParameter(this.$route.query.is_assign_category),
				brand_id: Number(this.$route.query.brand_id) || null,
				type: this.$route.query.type || null,
				is_new: getBooleanQueryParameter(this.$route.query.is_new),
				selling_price_from: !isEmpty(this.$route.query.selling_price_from) ? Number(this.$route.query.selling_price_from) : null,
				selling_price_to: !isEmpty(this.$route.query.selling_price_to) ? Number(this.$route.query.selling_price_to) : null,
				created_from: this.$route.query.created_from || null,
				created_to: this.$route.query.created_to || null,
				is_list_visible: getBooleanQueryParameter(this.$route.query.is_list_visible),
				is_search_visible: getBooleanQueryParameter(this.$route.query.is_search_visible),
				is_detail_visible: getBooleanQueryParameter(this.$route.query.is_detail_visible),
				sort_by: this.$route.query.sort_by || null,
				sort_direction: this.$route.query.sort_direction || null,
				show_only_product_group: this.$route.query.show_only_product_group || null,
				r: randomSearchString(),
			},
			modalImport: {
				isShowing: false,
				isUploading: false,
			},
			// Modal import
			showModalImportError: false,
			modalImporting: {
				isUploading: false,
				source: null,
			},
			modalImportError: {
				isShowing: false,
				errors: [],
			},
			modalExportLimit: {
				isShowing: false,
			},
			isExporting: false,
			isShowFilter: false,
			exportAPI: null,
		};
	},
	computed: {
		...mapState('products', {
			list: 'list',
		}),
		...mapGetters({
			products: 'products/productList',
			categoryInfo: 'categories/categoryInfo',
			categoryLists: 'categories/categoryLists',
			getBrandList: 'brands/getBrandList',
		}),
		isLoading() {
			return this.list.isLoading;
		},
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		brandList() {
			return [{ value: null, name: 'All brands' }, ...this.getBrandList.map(transformedBrandDropdownOption)];
		},
		filterVisibility() {
			let selectedValue = null;
			if (this.queryParams.is_list_visible) {
				selectedValue = PRODUCT_VISIBILITY_STATUSES.LIST;
			} else if (this.queryParams.is_search_visible) {
				selectedValue = PRODUCT_VISIBILITY_STATUSES.SEARCH;
			} else if (this.queryParams.is_detail_visible) {
				selectedValue = PRODUCT_VISIBILITY_STATUSES.DETAIL;
			} else if (this.queryParams.is_list_visible === 0
			&& this.queryParams.is_search_visible === 0
			&& this.queryParams.is_detail_visible === 0) {
				selectedValue = PRODUCT_VISIBILITY_STATUSES.HIDDEN;
			}
			const statusOptionValues = Object.values(PRODUCT_VISIBILITY_STATUS_OPTIONS);
			const findStatus = ({ value }) => value === selectedValue;
			return statusOptionValues.find(findStatus) || { name: null, value: null };
		},
		filterCategoryId() {
			return this.queryParams.is_assign_category === 0 ? -1 : this.queryParams.category_id;
		},
		filterCreatePeriod() {
			if (!this.queryParams.created_from || !this.queryParams.created_to) {
				return null;
			}

			return {
				start: new Date(this.queryParams.created_from),
				end: new Date(this.queryParams.created_to),
			};
		},
		priceRange() {
			if ((this.queryParams.selling_price_from || `${this.queryParams.selling_price_from}` === '0')
			&& (this.queryParams.selling_price_to || `${this.queryParams.selling_price_to}` === '0')) {
				if (this.queryParams.selling_price_from === this.queryParams.selling_price_to) {
					return `${numberFormat(priceFromAPI(this.queryParams.selling_price_to))}`;
				}

				return `${numberFormat(priceFromAPI(this.queryParams.selling_price_from))} - ${numberFormat(priceFromAPI(this.queryParams.selling_price_to))}`;
			}

			if (this.queryParams.selling_price_from || `${this.queryParams.selling_price_from}` === '0') {
				return `${numberFormat(priceFromAPI(this.queryParams.selling_price_from))} - ...`;
			}

			if (this.queryParams.selling_price_to || `${this.queryParams.selling_price_to}` === '0') {
				return `... - ${numberFormat(priceFromAPI(this.queryParams.selling_price_to))}`;
			}

			return null;
		},
		pagination() {
			return this.list.meta;
		},
	},
	async created() {
		this.isShowFilter = this.hasFilter;
		this.resetCategoryInfo();

		let getCategory;
		const getProducts = this.getProducts(this.queryParams);
		const getCategories = this.getCategories();

		// Get category info if category param is exist
		if (this.queryParams.category_id) {
			getCategory = this.getCategory(this.queryParams.category_id);
		}

		const getBrands = this.getBrands({ per_page: 'all' });

		await Promise.all([
			getProducts,
			getCategories,
			getCategory,
			getBrands,
		]);
	},
	methods: {
		...mapActions({
			getProducts: 'products/getProducts',
			getCategories: 'categories/getCategories',
			getCategory: 'categories/getCategory',
			resetCategoryInfo: 'categories/resetCategoryInfo',
			getBrands: 'brands/getBrands',
			showToast: 'toast/showToast',
		}),

		updateUrlParams() {
			const query = cleanObject(JSON.parse(JSON.stringify(this.queryParams)));
			this.$router.push({ query }).catch(() => {});
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleCategory(id) {
			this.queryParams.page = null;
			if (!id) {
				this.queryParams.category_id = null;
				this.queryParams.is_assign_category = null;
			} else if (id === -1) {
				this.queryParams.category_id = null;
				this.queryParams.is_assign_category = 0;
			} else {
				this.queryParams.category_id = id;
				this.queryParams.is_assign_category = null;
			}
			this.updateUrlParams();
		},
		handleStatus({ isActive, isEOL }) {
			this.queryParams.page = null;
			this.queryParams.is_active = isActive;
			this.queryParams.is_eol = isEOL;

			this.updateUrlParams();
		},
		handleFilterChange(key, event) {
			this.queryParams.page = null;
			this.queryParams[key] = event.value;
			this.updateUrlParams();
		},
		handleFilterVisibilityChange(value) {
			this.queryParams.page = null;
			this.queryParams.is_list_visible = null;
			this.queryParams.is_search_visible = null;
			this.queryParams.is_detail_visible = null;
			switch (value.value) {
				case PRODUCT_VISIBILITY_STATUSES.LIST:
					this.queryParams.is_list_visible = 1;
					break;
				case PRODUCT_VISIBILITY_STATUSES.SEARCH:
					this.queryParams.is_search_visible = 1;
					break;
				case PRODUCT_VISIBILITY_STATUSES.DETAIL:
					this.queryParams.is_detail_visible = 1;
					break;
				case PRODUCT_VISIBILITY_STATUSES.HIDDEN:
					this.queryParams.is_list_visible = 0;
					this.queryParams.is_search_visible = 0;
					this.queryParams.is_detail_visible = 0;
					break;
				default: break;
			}
			this.updateUrlParams();
		},
		handleFilterCreateChange(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.created_from = start;
			this.queryParams.created_to = end;
			this.updateUrlParams();
		},
		handleFilterPriceChange(value) {
			this.queryParams.page = null;
			this.queryParams.selling_price_from = value.from;
			this.queryParams.selling_price_to = value.to;
			this.updateUrlParams();
		},
		handleSortChange({ key, direction }) {
			this.queryParams.sort_by = key;
			this.queryParams.sort_direction = direction;
			this.updateUrlParams();
		},
		handleShowOnlyProductGroup(value) {
			this.queryParams.show_only_product_group = value ? 1 : null;
			this.updateUrlParams();
		},
		getSelectedValue(selectedValue = null, options = []) {
			const statusOptionValues = Object.values(options);
			const findStatus = ({ value }) => value === selectedValue;
			return statusOptionValues.find(findStatus) || { name: null, value: null };
		},
		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},
		resetFilter() {
			this.queryParams = {
				q: null,
				page: null,
				per_page: null,
				category_id: null,
				is_active: null,
				is_eol: null,
				is_assign_category: null,
				brand_id: null,
				type: null,
				is_new: null,
				selling_price_from: null,
				selling_price_to: null,
				created_from: null,
				created_to: null,
				is_list_visible: null,
				is_search_visible: null,
				is_detail_visible: null,
			};

			this.updateUrlParams();
		},
		async exportProduct(type) {
			switch (type) {
				case PRODUCT_EXPORT.PRODUCT_INFO:
					this.exportAPI = exportProductInfoAPI;
					break;
				case PRODUCT_EXPORT.PRODUCT_PRICE:
					this.exportAPI = exportProductPriceAPI;
					break;
				case PRODUCT_EXPORT.PRODUCT_INFO_DYNAMIC_FIELD:
					this.exportAPI = exportProductInfoDynamicFieldAPI;
					break;
				case PRODUCT_EXPORT.PRODUCT_GROUP:
					this.exportAPI = exportProductGroupAPI;
					break;
				case PRODUCT_EXPORT.PRODUCT_SHORT_ATTRIBUTE:
					this.exportAPI = exportProductShortAttributeAPI;
					break;
				case PRODUCT_EXPORT.APPLE_CARE_PRODUCTS:
					this.exportAPI = exportProductAppleCareAPI;
					break;
				default:
					break;
			}

			this.$refs['modal-export'].open();
		},
		importProductInfo() {
			this.importWrapper(importProductInfoAPI);
		},
		importProductInfoDynamicField() {
			this.importWrapper(importProductInfoDynamicFieldAPI);
		},
		importProductPrice() {
			this.importWrapper(importProductPriceAPI);
		},
		importProductGroup() {
			this.importWrapper(importProductGroupAPI);
		},
		importProductShortAttribute() {
			this.importWrapper(importProductShortAttributeAPI);
		},
		importProductAppleCare() {
			this.importWrapper(importProductAppleCareAPI);
		},
		closeImportErrorDialog() {
			this.modalImportError.isShowing = false;
		},
		handleCloseModalExportLimit() {
			this.modalExportLimit.isShowing = false;
		},
		cancelUpload() {
			this.modalImporting.isUploading = false;
			this.modalImporting.source.cancel('Operation canceled by the user');
		},
		importWrapper(api) {
			const fileSelector = document.createElement('input');
			fileSelector.setAttribute('type', 'file');
			fileSelector.setAttribute('accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel');
			fileSelector.onchange = async () => {
				const file = fileSelector.files.item(0);
				this.modalImporting.source = CancelToken.source();
				const params = {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					cancelToken: this.modalImporting.source.token,
					onUploadProgress: (progress) => {
						const { loaded, total } = progress;
						const percent = Math.floor((loaded * 100) / total);
						this.modalImporting.isUploading = percent !== 100;
					},
				};

				try {
					await api(file, params);
					this.showToast({
						content: this.$t('page.products.import.successMessage'),
						header: this.$t('global.successMessageTitle'),
						type: 'success',
					});
				} catch (e) {
					this.modalImportError.isShowing = true;
					this.modalImportError.errors = e.response.data.errors;
				}
			};
			fileSelector.click();
		},
		async handleExportProduct() {
			this.isExporting = true;

			try {
				await this.exportAPI({
					q: this.queryParams.q,
					category_id: this.queryParams.category_id,
					is_active: this.queryParams.is_active,
					is_eol: this.queryParams.is_eol,
					is_assign_category: this.queryParams.is_assign_category,
					brand_id: this.queryParams.brand_id,
					type: this.queryParams.type,
					is_new: this.queryParams.is_new,
					selling_price_from: this.queryParams.selling_price_from,
					selling_price_to: this.queryParams.selling_price_to,
					created_from: this.queryParams.created_from,
					created_to: this.queryParams.created_to,
					is_list_visible: this.queryParams.is_list_visible,
					is_search_visible: this.queryParams.is_search_visible,
					is_detail_visible: this.queryParams.is_detail_visible,
				});

				this.showToast({
					header: this.$t('global.successMessageTitle'),
					content: `Your request has been completed.`,
					type: 'success',
				});
			} catch (e) {
				this.showToast({
					header: this.$t('global.errorMessageExport'),
					content: this.$t('global.errorMessage'),
					type: 'danger',
				});
			} finally {
				this.isExporting = false;
				this.$refs['modal-export'].close();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.filter-box {
		background-color: $color-gray-100;
	}

	::v-deep .import-dropdown-menu,
	::v-deep .export-dropdown-menu {
		box-shadow: $box-shadow-component;
	}

	::v-deep .btn-import,
	::v-deep .btn-export {
		display: inline-block;
		padding-right: rem(12);

		.dropdown-toggle {
			box-shadow: none !important;
		}
	}

	::v-deep .table-product {
		th,
		td {
			font-size: rem(14);
		}

		.thumbnail {
			width: rem(54);
			height: rem(54);
			object-fit: contain;
		}

		.col-stock-reserved {
			min-width: rem(118);
		}

		.td-stock-total {
			border-left: 1px solid $color-gray-200;
		}

		.td-stock-available {
			border-right: 1px solid $color-gray-200;
		}
	}

	.category-list,
	.visibility-list {
		list-style: none;
		padding: 0;

		li {
			@include typo-body-2;
		}
	}

	.realtime-stock {
		&-title {
			color: $color-black-45;
		}

		&-value {
			color: $color-black-70;
		}
	}

	// Set placeholder of filter by price range
	::v-deep .price-input {
		color: $color-black-90;
		border: 1px solid $color-gray-400;

		@include placeholder-black;
	}

	// Set placeholder of filter by date range
	::v-deep .form-control-date {
		input[type="input"] {
			@include placeholder-black;
		}
	}

	.item-filter-mergin-bottom {
		margin-bottom: 20px;
	}
</style>
